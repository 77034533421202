import { UserSchema } from '@IJGN/potential'
import { z } from 'zod'

export const CompanyPersonSchema = UserSchema.extend({
  email: z
    .string()
    .email({ message: 'メールの形式が正しくありません' })
    .min(1, { message: 'メールアドレスを入力してください' }),
  name: z
    .string()
    .min(1, { message: '企業担当者名を入力してください' })
    .max(255, { message: '最大255以下の文字数で入力してください' }),
  company_code: z.string().optional(),
  kana: z
    .string()
    .regex(/^[ぁ-んー]+$/u, { message: 'ひらがなで入力してください' })
    .min(1, { message: '企業担当者名（かな）を入力してください' })
    .max(255, { message: '最大255以下の文字数で入力してください' }),
  memo: z.string().max(4000, { message: '最大4000以下の文字数で入力してください' }).nullish(),
  active: z.boolean(),
  initial_activated_at: z.string().optional().nullish(),
})

export const CompanyPersonLoginSchema = z.object({
  email: z
    .string()
    .email({ message: 'メールの形式が正しくありません' })
    .min(1, { message: 'メールアドレスを入力してください' }),
  password: z.string().min(1, { message: 'パスワードを入力してください' }),
})

export const CompanyPersonChangeEmailSchema = z.object({
  email: z
    .string()
    .email({ message: 'メールの形式が正しくありません' })
    .min(1, { message: 'メールアドレスを入力してください' }),
})

export const CompanyPersonPasswordChangeSchema = z
  .object({
    current_password: z.string().min(8, { message: '現在のパスワードを8文字以上で入力してください' }),
    new_password: z.string().min(8, { message: '新しいパスワードを8文字以上で入力してください' }),
    new_password_confirm: z.string().min(8, {
      message: '新しいパスワード（確認）を8文字以上で入力してください',
    }),
  })
  .superRefine(({ new_password, new_password_confirm }, ctx) => {
    if (new_password !== new_password_confirm) {
      ctx.addIssue({
        path: ['new_password_confirm'],
        code: 'custom',
        message: 'パスワードが一致しません',
      })
    }
  })

export const CompanyPersonForgotPasswordSchema = CompanyPersonSchema.pick({
  email: true,
})

export const CompanyPersonPasswordResetSchema = z
  .object({
    new_password: z.string().min(1, { message: '新しいパスワードを入力してください' }),
    new_password_confirm: z.string().min(8, { message: '新しいパスワードを8文字以上で入力してください' }),
  })
  .superRefine(({ new_password, new_password_confirm }, ctx) => {
    if (new_password !== new_password_confirm) {
      ctx.addIssue({
        path: ['new_password_confirm'],
        code: 'custom',
        message: 'パスワードが一致しません',
      })
    }
  })

export const CompanyPersonSetInitialPasswordSchema = z
  .object({
    token: z.string().min(1, { message: 'URLが無効です' }),
    new_password: z.string().min(1, { message: '新しいパスワードを入力してください' }),
    new_password_confirm: z.string().min(8, { message: '新しいパスワードを8文字以上で入力してください' }),
  })
  .superRefine(({ new_password, new_password_confirm }, ctx) => {
    if (new_password !== new_password_confirm) {
      ctx.addIssue({
        path: ['new_password_confirm'],
        code: 'custom',
        message: 'パスワードが一致しません',
      })
    }
  })

export type CompanyPerson = z.infer<typeof CompanyPersonSchema>
export type CompanyPersonLogin = z.infer<typeof CompanyPersonLoginSchema>
export type CompanyPersonPasswordChange = z.infer<typeof CompanyPersonPasswordChangeSchema>
export type CompanyPersonForgotPassword = z.infer<typeof CompanyPersonForgotPasswordSchema>
export type CompanyPersonPasswordReset = z.infer<typeof CompanyPersonPasswordResetSchema>
export type CompanyPersonSetInitialPassword = z.infer<typeof CompanyPersonSetInitialPasswordSchema>
